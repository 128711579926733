<template>
  <div class="contact animation" style="animation-delay: 1s" v-if="render">
    <!-- intro -->
    <v-container fluid>
      <v-row>
        <v-col class="pa-0 intro position-relative">
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              contactSingleton.img_intro.path
            "
            width="100%"
            height="100%"
            class="intro__asset"
            eager
          ></v-img>
          <!-- <video
            autoplay
            loop
            playsinline
            width="100%"
            height="100%"
            style="object-fit: cover"
            class="intro__asset"
          >
            <source src="@/assets/img/Video-1280x720.mp4" />
          </video> -->
          <v-row no-gutters justify="center" class="flex-column fill-height">
            <v-col cols="auto">
              <div class="logo-header" v-if="$vuetify.breakpoint.smAndUp"></div>
              <router-link to="/" v-else>
                <v-img
                  style="z-index: 7"
                  alt="Marisol Logo"
                  class="shrink logo-header mx-auto"
                  contain
                  src="@/assets/icons/MariSolMalibu_logo.svg"
                  transition="scale-transition"
                />
              </router-link>

              <!-- btn para el menú mobile -->
              <div
                class="text-center mt-6 d-sm-none"
                v-if="$vuetify.breakpoint.xs"
              >
                <v-btn
                  @click="$emit('open')"
                  :ripple="false"
                  text
                  class="letter-spacing-0 not-text-transform"
                >
                  <span class="continuos-texts white--text">Menu</span>
                </v-btn>
              </div>
              <!-- /btn para el menú mobile -->
            </v-col>
            <v-col cols="auto" class="position-relative mt-auto">
              <v-row
                no-gutters
                justify="center"
                align="center"
                class="text-center"
              >
                <v-col cols="auto">
                  <!-- <span
                    class="white--text titles-medium d-block sabon-regular animation"
                    style="animation-delay: 2s"
                    v-html="contactSingleton.title_intro"
                  >
                  </span> -->
                  <!-- <v-btn
                    v-if="contactSingleton.info_reservation"
                    to="/contact/book-a-tour"
                    color="white"
                    tile
                    elevation="0"
                    class="mt-11"
                    dark
                    :ripple="false"
                  >
                    <span
                      class="black--text continuos-texts"
                      style="text-transform: none"
                      >Search Reservations</span
                    >
                  </v-btn> -->
                  <!--
                  
                  <p
                    class="d-block text-random-5 line-height-title-2 mx-auto white--text"
                    style="max-width: 855px; margin-top: 50px"
                  >
                    We are currently open by reservation to financially
                    qualified guests. Unless it is apparent, we may ask for your
                    proof of funds prior to your reservation confirmation.
                  </p>
                  -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="mt-auto position-relative">
              <v-row no-gutters justify="center" align="end">
                <v-col cols="auto">
                  <!-- <v-img
                    src="@/assets/icons/MALIBU_logo.svg"
                    class="intro__logo-2"
                  ></v-img> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- /intro -->

    <!-- content new -->
    <v-container fluid class="block-second padding-custom-general-3">
      <v-row no-gutters>
        <!-- <v-col class="text-center space-between-boxes" cols="12">
          <v-row no-gutters class="fill-height" align="center">
            <v-col>
              <span class="d-block text-random-5 line-height-title-2">{{
                contactSingleton.title_discover
              }}</span>
              <div class="line-height-random-135">
                <a
                  :href="'mailto:' + contactSingleton.email_discover"
                  class="not-text-decoration black--text"
                  target="_blank"
                >
                  <span class="text-random-4 sabon-regular">{{
                    contactSingleton.email_discover
                  }}</span>
                </a>
              </div>

              <template v-if="contactSingleton.info_reservation">
                <span class="text-random-4 sabon-regular"
                  >Reservations by Appointment Only</span
                >
              </template>

              <a
                href="https://goo.gl/maps/coKsjZRumC8VvAyj7"
                class="d-block text-random-5 line-height-title-2 my-12 mt-11 black--text"
                target="_blank"
              >
                11809 Ellice Street, Malibu, CA 90265
              </a>

              <div class="my-12" style="margin-top: 50px; margin-bottom: 50px">
                <iframe src="https://snazzymaps.com/embed/327904" width="100%" height="600px" style="border:none;"></iframe>

                <iframe
                  src="https://snazzymaps.com/embed/430315"
                  width="100%"
                  height="600px"
                  style="border: none"
                ></iframe>
              </div>

              <template v-if="contactSingleton.info_reservation">
                <p
                  class="d-block text-random-5 line-height-title-2 mx-auto"
                  style="max-width: 855px; margin-top: 50px"
                >
                  We are currently open by reservation to financially qualified
                  guests. Unless it is apparent, we may ask for your proof of
                  funds prior to your reservation confirmation.
                </p>
              </template>

              <p class="continuos-texts block__third__margin-p line-height-description">
                We are currently open by reservation to financially qualified guests. Unless it is apparent, we may ask for your proof of funds prior to your reservation confirmation.  
              </p>

              <v-btn
                v-if="contactSingleton.info_reservation"
                to="/contact/book-a-tour"
                color="black"
                tile
                width="235"
                elevation="0"
                class="mt-11"
                dark
                :ripple="false"
              >
                <span
                  class="white--text continuos-texts"
                  style="text-transform: none"
                  >Search Reservations</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <hr class="hr-custom" /> -->

        <v-col class="text-center height-column-contact" cols="12">
          <v-row no-gutters class="fill-height" align="center">
            <v-col>
              <span class="text-random-5 d-block line-height-title-2 pre-line">
                Questions? Contact us at
              </span>
              <div class="line-height-random-135">
                <a
                  href="mailto:sales@marisolmalibu.com"
                  class="not-text-decoration black--text d-block"
                  target="_blank"
                >
                  <span class="text-random-4 sabon-regular">
                    sales@marisolmalibu.com
                  </span>
                </a>

                <mailchimp-subscribe
                  url="https://marisolmalibu.us2.list-manage.com/subscribe/post-json"
                  list-id="e881d84d7d"
                  user-id="940f0546d0afa3cb202e6a56c"
                  @error="onError()"
                  @success="onSuccess()"
                >
                  <template v-slot="{ subscribe, setEmail, error, success, loading }">
                    <form @submit.prevent="subscribe">
                      <v-container>
                        <v-row justify="center">
                            <v-col cols="11" sm="auto" class="px-0 pt-3 pb-0 pb-md-3">
                                <input type="email" @input="setEmail($event.target.value)" placeholder="enter email address" class="newsletter-email-input" />
                            </v-col>
                            <v-col cols="11" sm="auto" class="px-0 py-0 py-md-3">

                                <button type="submit" class="newsletter-email-btn">Subscribe for updates</button>
                            </v-col>
                            <div v-if="error"></div>
                            <div v-if="success"></div>
                            <div v-if="loading"></div>
                        </v-row>
                      </v-container>
                    </form>
                  </template>
                </mailchimp-subscribe>

              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-img
            :src="
              'https://content.marisolmalibu.com' +
              contactSingleton.img_contact.path
            "
            class="img-contact"
            eager
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <!-- /content new -->
    <v-snackbar
        v-model="snackbarEmail"
        top
        app
        color="black"
        class="text-center"
        >
        <div class="text-center">
            <span class="white--text continuos-texts">Thank you!</span>
        </div>
    </v-snackbar>

    <router-view @update="showNotification()"></router-view>
  </div>
</template>
<script>
import MailchimpSubscribe from 'vue-mailchimp-subscribe'
export default {
  components: {
    MailchimpSubscribe,
  },
  metaInfo: {
    title: "Contact | Marisol Malibu",
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      {
        vmid: "og:description",
        property: "og:description",
        name: "description",
        content:
          "Malibu's First Zero Carbon Homes. Sustainably sourced, built from local, renewable materials. Enduring expertise, designed by leading architects, engineers, and sustainability experts, in collaboration with the International Living Future Institute.",
      },
    ],
  },
  name: "Contact",
  data() {
    return {
      render: false,
      contactSingleton: {},
      rows: 15,
      snackbar: false,
      snackbarEmail: false
    };
  },
  created() {
    fetch("https://content.marisolmalibu.com/api/singletons/get/contact")
      .then((data) => data.json())
      .then((data) => (this.contactSingleton = data))
      .then((this.render = true))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.$emit("update", 1);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onSuccess: function () {
        this.snackbarEmail = true
        var aux = this;
        setTimeout(() => {
            aux.snackbarEmail = false
        }, 2000);
    },
    onError: function() {
        this.snackbarEmail = true
        var aux = this;
        setTimeout(() => {
            aux.snackbarEmail = false
        }, 2000);
    },
    onResize() {
      // altura del textarea
      if (window.innerWidth <= 599) this.rows = 4;
      else if (window.innerWidth === 1024 && window.innerHeight == 1366)
        this.rows = 13;
      else if (window.innerWidth >= 600 && window.innerWidth <= 1263)
        this.rows = 8;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.rows = 9;
      else if (window.innerWidth >= 1904 && window.innerWidth <= 2299)
        this.rows = 10;
      else if (window.innerWidth >= 2300 && window.innerWidth <= 3629)
        this.rows = 12;
      else if (window.innerWidth >= 3630) this.rows = 20;
    },
    showNotification: function () {
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$xxl: 3630px;
$xl-intermedia: 2300px;
$xl: 1903px;
$lg: 1263px;
$tablet-h: 1024px;
$md: 959px;
$sm: 599px;
.space-between-boxes {
  padding-top: 130px !important;
  padding-bottom: 142px !important;

  @media screen and (max-width: $sm) {
    padding-top: 35px !important;
    padding-bottom: 47px !important;
  }
}
</style>